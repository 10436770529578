import fetch from '../utils/fetch'

export function getInquiryList (query) {
  return fetch({
    url: '/h5app/wxapp/inquiry/getInquiryList',
    method: 'get',
    params: query
  })
}

export function exportAll (query) {
  return fetch({
    url: '/h5app/wxapp/inquiry/export',
    method: 'get',
    params: query
  })
}
